<template>
    <a-layout style="height:100%;">
        <a-layout-content>
            <div class="yj-conten"
                 style="background:#fff;padding:20px;min-height: calc(100vh - 114px);min-width:max-content">
                <a-form layout="horizontal" style="padding:10px 00px;height:auto;">
                    <a-form-item label
                                 style="margin-bottom:10px;"
                                 :label-col="formItemLayout.labelCol"
                                 :wrapper-col="formItemLayout.wrapperCol">
                        <a-form layout="inline">
                            <a-form-item>
                                <label class="yj-partner-check_lab">类别：</label>
                                <a-radio-group default-value="-1" @change="radioChange">
                                    <a-radio-button value="-1">全部</a-radio-button>
                                    <a-radio-button value="1">待审核</a-radio-button>
                                    <a-radio-button value="3">已拒绝</a-radio-button>
                                </a-radio-group>
                            </a-form-item>
                        </a-form>
                    </a-form-item>
                    <a-form-item label
                                 style="margin-bottom:0px;"
                                 :label-col="formItemLayout.labelCol"
                                 :wrapper-col="formItemLayout.wrapperCol">
                        <a-form layout="inline">
                            <a-form-item>
                                <label class="yj-partner-check_lab">待审核人：</label>
                                <a-input placeholder="姓名/电话" style="width:260px;" v-model="WaitName" />
                            </a-form-item>
                            <a-form-item label="推荐人">
                                <a-input placeholder="姓名/电话" style="width:260px;" v-model="AgentName"></a-input>
                            </a-form-item>
                            <a-form-item label="时间">
                                <a-range-picker style="width:260px;" :value="Time" @change="handleChange" />
                            </a-form-item>

                            <a-form-item label>
                                <a-button type="primary" class="margin-r" @click="QueryData">查询</a-button>
                                <a-button type="primary" ghost @click="exportdata">导出</a-button>
                            </a-form-item>
                        </a-form>
                    </a-form-item>
                </a-form>
                <div class="yj-list-box" style="height:calc(100% - 160px);">
                    <div class="yj_list">
                        <a-table :columns="columns" bordered
                                 :data-source="PartnerCheck"
                                 rowKey="ID"
                                 :pagination="false"
                                 :row-selection="{selectedRowKeys:selectedRowKeys,onChange:onSelectChange}">
                            <template v-slot:Infotemplate="text,record">
                                <div class="yj_lists_commodity">
                                    <div class="yj_lists_commodity_img yj_lists_partner_img">
                                        <div>
                                            <img :src="record.PartnerImg ||'/Image/default-avatar.svg'" />
                                        </div>
                                    </div>
                                    <div class="yj_lists_commodity_text">
                                        <div>{{record.PartnerName}}</div>
                                        <div>{{record.PartnerPhone == null ? '': record.PartnerPhone}} {{(record.mobileLocation)}}</div>
                                        <div>{{record.Address == null ? '':record.Address}}</div>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:Statetemplate="text,record">
                                <small v-if="text == 1">{{ShowEnum(text)}}</small>
                                <a-popover placement="rightTop">
                                    <template slot="content">
                                        <p>{{record.Remark}}</p>
                                    </template>
                                    <template slot="title">
                                        <span>拒绝原因</span>
                                    </template>
                                    <small class=" ant-tag ant-tag-volcano" v-if="text == 3">{{ShowEnum(text)}}</small>
                                </a-popover>
                            </template>
                            <template v-slot:Actiontemplate="record">
                                <div v-if="record.CheckState ==1">
                                    <a-button type="link" size="small" @click="showAgreenConfirm(record)">同意</a-button>
                                    <a-divider type="vertical"></a-divider>
                                    <a-button type="link"
                                              size="small"
                                              @click="modalVisibleClick(record,2)"
                                              style="color:red">拒绝</a-button>
                                </div>
                            </template>
                        </a-table>
                    </div>
                </div>
                <div style="padding:10px 0 0" v-if="pagination.total>0">
                    <a-row>
                        <a-col :span="8" class="textal_l">
                            <a-button type="primary" @click="modalVisibleClick(2,3)">批量审核</a-button>
                        </a-col>
                        <a-col :span="16" class="textal_r" v-if="pagination.total>10">
                            <a-pagination :total="pagination.total"
                                          show-quick-jumper
                                          :default-current="pagination.current"
                                          @change="onPaginationChange"></a-pagination>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </a-layout-content>
        <a-modal :visible="modalVisible"
                 title="审核结果"
                 centered
                 okText="确认"
                 cancelText="取消"
                 :maskClosable="false"
                 @cancel="CheckCancel"
                 @ok="CheckSubmit">
            <div style="margin-bottom:10px;">{{checkReason}}</div>
            <a-input placeholder="请输入" v-model="Message"></a-input>
        </a-modal>
        <a-modal :visible="batchModalVisible"
                 title="批量审核结果"
                 centered
                 okText="确认"
                 :maskClosable="false"
                 cancelText="取消"
                 @ok="CheckSubmit"
                 @cancel="CheckCancel">
            <a-radio-group name="radioGroup"
                           @change="checkRadioChange"
                           style="margin-bottom:20px"
                           :options="options"
                           :value="result">

            </a-radio-group>
            <a-input v-if="result == '3'" :placeholder="checkReason" v-model="Message"></a-input>
        </a-modal>
    </a-layout>


</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    const options = [{
        label: "同意",
        value: "1"
    }, {
        label: "拒绝",
        value: "3"
    }]
    export default {
        name: "PartnerCheck_PartnerCheck",
        data() {
            return {
                options,
                columns: [{
                    title: "待审核团队信息",
                    dataIndex: "ID",
                    scopedSlots: { customRender: "Infotemplate" }
                },
                {
                    title: "推荐人",
                    width: 220,
                    dataIndex: "AgentName"
                },
                {
                    title: "申请时间",
                    width: 220,
                    dataIndex: "CreateTime",
                }, {
                    title: "状态",
                    width: 220,
                    dataIndex: "CheckState",
                    scopedSlots: { customRender: "Statetemplate" }
                },
                {
                    title: "操作",
                    key: "action",
                    scopedSlots: { customRender: "Actiontemplate" }
                }],
                batchModalVisible: false, //批量审核弹框
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 24 }
                    }
                },
                config: {
                    rules: [
                        { type: "object", required: true, message: "Please select time!" }
                    ]
                },
                rangeConfig: {
                    rules: [
                        { type: "array", required: true, message: "Please select time!" }
                    ]
                },
                CheckStatusEnum: [],
                checkReason: "", //审核原因提示信息
                PartnerCheck: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1
                },
                CheckStatus: -1,
                WaitName: "",
                AgentName: "",
                BeginTime: "",
                EndTime: "",
                result: "1", //审核类型
                Time: [],
                Message: "", //审核原因
                modalVisible: false, //单个审核
                SingleCheckID: 0, //单个审核
                selectedRowKeys: []
            };
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "time_related_controls" });
        },
        methods: {
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },
            //批量审核结果（同意，拒绝）
            checkRadioChange(e) {
                console.log(e)
                this.result = e.target.value;
            },
            //单个同意
            showAgreenConfirm(e) {
                var self = this;
                this.$confirm({
                    title: "确定要同意吗?",
                    content: "",
                    cancelText: "取消",
                    okText: "确认",
                    onOk() {
                        self.SingleCheckID = e.ID;
                        self.result = "1";
                        self.CheckSubmit();
                    },
                    onCancel() {
                        self.$destroyAll();
                    },
                    class: "test"
                });
            },
            //导出
            exportdata: function () {
                var self = this
                var op = {
                    url: "/PartnerModule/PartnerCheck/GetUserPartnerCheck",
                    data: {
                        CheckStatus: self.CheckStatus,
                        WaitName: self.WaitName,
                        AgentName: self.AgentName
                    }, //下载接口参数
                    FileName: "团队审核.xlsx", //下载文件重命名
                    ContentType:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" //文件Mine类型
                };
                http.DownLoadFile(op);
            },



            //加载审核列表
            GetPartnerCheck() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerCheck/GetPartnerCheck",
                    data: {
                        PageIndex: self.pagination.current,
                        CheckStatus: self.CheckStatus,
                        WaitName: self.WaitName,
                        AgentName: self.AgentName,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime
                    },
                    OnSuccess: function (res) {
                        if (res.data.Total > 0) {
                            res.data.Data.forEach(element => {
                                element.CreateTime = util.TimeStamptoDateTime(element.CreateTime, 'yyyy-MM-dd hh:mm:ss')
                            });
                        }
                        self.PartnerCheck = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            getCheckStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.PartnerModule",//程序集名称
                        TypeName: "Welcome.PartnerModule.Enumerate.CheckStatusEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.CheckStatusEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {
                var Self = this;
                var item = Self.CheckStatusEnum.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            },
            //审核列表切换
            radioChange(e) {
                this.pagination.current = 1;
                this.selectedRowKeys = []
                this.CheckStatus = e.target.value;
                this.GetPartnerCheck();
            },
            //查询按钮事件
            QueryData() {
                this.pagination.current = 1;
                if (this.Time.length > 0) {
                    this.BeginTime = util.formatDate(this.Time[0]._d);
                    this.EndTime = util.formatDate(this.Time[1]._d);
                }
                this.GetPartnerCheck();
            },
            //跳页
            onPaginationChange(e) {
                this.pagination.current = e;
                this.selectedRowKeys = []
                this.GetPartnerCheck();
            },
            //时间选择监控事件
            handleChange(value) {
                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                }
                this.Time = value;
            },
            //审核弹框
            modalVisibleClick(e, Checktype) {
                var self = this
                if (Checktype == 2) {
                    self.modalVisible = true;
                    self.SingleCheckID = e.ID;
                    self.checkReason = "请输入拒绝原因:";
                    self.result = "3";
                } else {
                    this.SingleCheckID = 0;
                    if (this.selectedRowKeys.length <= 0) {
                        this.$message.error("请选择待审核团队");
                        return;
                    }
                    this.batchModalVisible = !this.batchModalVisible;
                    this.checkReason = "请输入拒绝原因";
                }
            },
            CheckCancel() {
                this.Message = null;
                this.result = "1";
                console.log(this.result)
                this.batchModalVisible = false;
                this.modalVisible = false;
            },
            //提交审核
            CheckSubmit() {
                var self = this;
                if (self.result == "3") {
                    if (self.Message == "") {
                        self.$message.success("请输入拒绝原因");
                        return;
                    }
                }

                var op = {
                    url: "/PartnerModule/PartnerCheck/UpdataPartnerCheck",
                    data: {
                        CheckId: self.SingleCheckID,
                        CheckIds: self.selectedRowKeys,
                        Result: self.result,
                        Message: self.Message
                    },
                    OnSuccess: function () {
                        self.$message.success("操作成功")
                        self.Message = null
                        self.SingleCheckID = 0;
                        self.modalVisible = false;
                        self.result = "1"

                        self.batchModalVisible = false;
                        self.selectedRowKeys = []
                        self.GetPartnerCheck();
                    }
                };
                http.Post(op);
            }
        },
        mounted: function () {
            this.GetPartnerCheck();
            this.getCheckStatusEnum();
        }
    };
</script>

<style>

    .ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
    }
</style>